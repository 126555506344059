<template>
  <div class="container pt-5">
    <div class="text-center">
      <img src="/img/logo-preta.png" width="200" alt="Logo Bike Registrada" />
    </div>
    <div class="row pt-5">
      <div class="col-md-4 offset-md-4 col-sm-12">
        <strong>Conta de parceiros Bike Registrada</strong>
        <div>
          <b-form-group label=" Escolha o tipo de conta" v-slot="{ ariaDescribedby }">
            <div class="row">
              <div class="col-6">
                <b-form-radio v-model="pessoaJuridica" :aria-describedby="ariaDescribedby" name="some-radios"
                  :value="true">Pessoa Jurídica</b-form-radio>
              </div>
              <div class="col-6">
                <b-form-radio v-model="pessoaJuridica" :aria-describedby="ariaDescribedby" name="some-radios"
                  :value="false">Pessoa física</b-form-radio>
              </div>
            </div>
          </b-form-group>
        </div>
        <ValidationObserver v-slot="{ handleSubmit }">
          <form class="mt-2" @submit.prevent="handleSubmit(login)">
            <div v-if="pessoaJuridica" class="form-group">
              <ValidationProvider name="CNPJ" rules="required|cnpj" v-slot="{ errors, classes }">
                <input v-model="cnpj" type="text" class="form-control" :class="classes" v-mask="'##.###.###/####-##'"
                  placeholder="00.000.000/0000-00" />
                <span class="invalid-feedback">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>

            <div v-else class="form-group">
              <ValidationProvider name="Cpf" rules="required|cpf" v-slot="{ errors, classes }">
                <input v-model="cnpj" type="text" class="form-control" :class="classes" v-mask="'###.###.###-##'"
                  placeholder="000.000.000-00" />
                <span class="invalid-feedback">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>

            <div class="form-group">
              <ValidationProvider name="senha" rules="required" v-slot="{ errors, classes }">
                <input v-model="senha" type="password" class="form-control" :class="classes"
                  placeholder="Digite sua senha" @keyup.enter="login()" />
                <span class="invalid-feedback">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>

            <button type="submit" class="btn btn-success btn-block" :disabled="loadingLogin">
              <span v-if="loadingLogin">Por favor aguarde...</span>
              <span v-else>Fazer login e continuar</span>
            </button>
          </form>
        </ValidationObserver>

        <hr />
        <div class="text-center p-2">
          <router-link to="/nova-conta-parceiro">Cadastre-se agora</router-link>
        </div>
        <div class="text-center p-2">
          <router-link to="/recuperar-senha-parceiro">
            Esqueci minha senha
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import config from "../../config";
import auth from "../../auth";

export default {
  components: {},
  data() {
    return {
      config,
      cnpj: undefined,
      senha: undefined,
      loadingLogin: false,
      qtdErroLogin: 0,
      pessoaJuridica: true,
    };
  },
  created() { },
  methods: {
    async login() {
      this.loadingLogin = true;

      var data = {
        login: this.cnpj,
        senha: this.senha,
      };

      try {
        var response = await axios.post(
          `${config.API_URLV2}/auth/LoginLojista`,
          data
        );

        auth.setUserInfo(response.data);

        this.$router.replace({ path: "/dashboard/" });
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.invalido
        ) {
          var msg = error.response.data.msg;
          Swal.fire({
            title: msg,
            text: "",
            icon: "error",
            confirmButtonText: "Ok",
          });
        }
        if (
          error.response &&
          error.response.data &&
          error.response.data.desativada
        ) {
          Swal.fire({
            title: "Atenção",
            html: `<p>Sua conta foi <b>temporariamente desativada</b>, entre em contato com o nosso suporte para mais informações.</p>`,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonColor: "#28a745",
            cancelButtonText: "Fechar",
            confirmButtonText: "Entrar em contato com o suporte",
          }).then((result) => {
            if (result.value) {
              window.open(
                "https://api.whatsapp.com/send?phone=556135224521&text=Olá, sou lojista parceiro e minha conta foi desativada, preciso de orientação",
                "_blank"
              );
            }
          });
          this.loadingLogin = false;
          return;
        }
        this.loadingLogin = false;
        this.qtdErroLogin++;
        if (this.qtdErroLogin >= 2) {
          this.$router.push("/recuperar-senha-parceiro");
        }
      }
      this.loadingLogin = false;
    },
  },
};
</script>
